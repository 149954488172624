<template>
	<div class="">
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<!-- 系统设置 -->
		  <el-tabs type="border-card" v-model="tabIndex" @tab-click="handleClick">
		    <el-tab-pane label="基础设置">
		      <el-form label-width="150px" label-position="left">
		          <el-form-item label="系统名称" prop="shop_name">
		             <el-input :value="shop_name" @input="vModel('shop_name',$event)" placeholder="请输入系统名称" size="small"></el-input>
		          </el-form-item>
				  <el-form-item label="系统全名" prop="system_name">
				     <el-input :value="system_name" @input="vModel('system_name',$event)" placeholder="请输入系统全名" size="small"></el-input>
				  </el-form-item>
		          <el-form-item label="备案信息" prop="shop_beian">
		             <el-input :value="shop_beian" @input="vModel('shop_beian',$event)" placeholder="请输入备案信息" size="small"></el-input>
		          </el-form-item>
				  <el-form-item label="系统描述" prop="shop_desc">
				     <el-input :value="shop_desc" @input="vModel('shop_desc',$event)" placeholder="请输入系统描述" size="small" type="textarea" :rows="3"></el-input>
				  </el-form-item>
				  <el-form-item>
					<el-button type="primary" size="small" @click="submit">确定</el-button>
				  </el-form-item>
		      </el-form>
		    </el-tab-pane>
			<el-tab-pane label="教师荣誉">
			  <el-form label-width="150px" label-position="left">
			      <el-form-item label="配置1">
					  <div class="w-100 flex align-center">
						  <div class="flex-1 flex align-center">
							  <el-input :value="jsry_1_name" @input="vModel('jsry_1_name',$event)" type="text" placeholder="请输入荣誉名称" size="small" clearable class="flex-1"></el-input>
						  </div>
						  <div class="flex-1 flex align-center ml-2">
							  <el-input :value="jsry_1_score" @input="vModel('jsry_1_score',$event)" type="number" placeholder="请输入荣誉分数" size="small" clearable class="flex-1"></el-input>
							  <span class="mx-2">分</span>
						  </div>
					  </div>
			      </el-form-item>
				  <el-form-item label="配置2">
					  <div class="w-100 flex align-center">
						  <div class="flex-1 flex align-center">
							  <el-input :value="jsry_2_name" @input="vModel('jsry_2_name',$event)" type="text" placeholder="请输入荣誉名称" size="small" clearable class="flex-1"></el-input>
						  </div>
						  <div class="flex-1 flex align-center ml-2">
							  <el-input :value="jsry_2_score" @input="vModel('jsry_2_score',$event)" type="number" placeholder="请输入荣誉分数" size="small" clearable class="flex-1"></el-input>
							  <span class="mx-2">分</span>
						  </div>
					  </div>
				  </el-form-item>
				  <el-form-item label="配置3">
					  <div class="w-100 flex align-center">
						  <div class="flex-1 flex align-center">
							  <el-input :value="jsry_3_name" @input="vModel('jsry_3_name',$event)" type="text" placeholder="请输入荣誉名称" size="small" clearable class="flex-1"></el-input>
						  </div>
						  <div class="flex-1 flex align-center ml-2">
							  <el-input :value="jsry_3_score" @input="vModel('jsry_3_score',$event)" type="number" placeholder="请输入荣誉分数" size="small" clearable class="flex-1"></el-input>
							  <span class="mx-2">分</span>
						  </div>
					  </div>
				  </el-form-item>
				  <el-form-item label="配置4">
					  <div class="w-100 flex align-center">
						  <div class="flex-1 flex align-center">
							  <el-input :value="jsry_4_name" @input="vModel('jsry_4_name',$event)" type="text" placeholder="请输入荣誉名称" size="small" clearable class="flex-1"></el-input>
						  </div>
						  <div class="flex-1 flex align-center ml-2">
							  <el-input :value="jsry_4_score" @input="vModel('jsry_4_score',$event)" type="number" placeholder="请输入荣誉分数" size="small" clearable class="flex-1"></el-input>
							  <span class="mx-2">分</span>
						  </div>
					  </div>
				  </el-form-item>
				  <el-form-item>
					<el-button type="primary" size="small" @click="submit">确定</el-button>
				  </el-form-item>
			  </el-form>
			</el-tab-pane>
			<el-tab-pane label="其他">
			  <el-form label-width="150px" label-position="left">
				  <el-form-item label="数字大屏密码" prop="bs_password">
				     <el-input :value="bs_password" @input="vModel('bs_password',$event)" placeholder="请输入数字大屏密码" size="small"></el-input>
				  </el-form-item>
				  <el-form-item>
					<el-button type="primary" size="small" @click="submit">确定</el-button>
				  </el-form-item>
			  </el-form>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import { mapState,mapMutations,mapGetters } from "vuex"
	import GlobalTips from "@/components/GlobalTips";
	export default {
		inject:['app'],
		components: {
			GlobalTips,
		},
		computed:{
			...mapState({
			  shop_name:state=>state.setting.shop_name,
			  company_name:state=>state.setting.company_name,
			  shop_address:state=>state.setting.shop_address,
			  shop_desc:state=>state.setting.shop_desc,
			  shop_beian:state=>state.setting.shop_beian,
			  kf_mobile:state=>state.setting.kf_mobile,
			  search_keywords:state=>state.setting.search_keywords,
			  shop_logo:state=>state.setting.shop_logo,
			  system_name:state=>state.setting.system_name,
			  dkl_bjs:state=>state.setting.dkl_bjs,
			  dkl_zkss:state=>state.setting.dkl_zkss,
			  dkl_xs:state=>state.setting.dkl_xs,
			  jsry_1_name:state=>state.setting.jsry_1_name,
			  jsry_1_score:state=>state.setting.jsry_1_score,
			  jsry_2_name:state=>state.setting.jsry_2_name,
			  jsry_2_score:state=>state.setting.jsry_2_score,
			  jsry_3_name:state=>state.setting.jsry_3_name,
			  jsry_3_score:state=>state.setting.jsry_3_score,
			  jsry_4_name:state=>state.setting.jsry_4_name,
			  jsry_4_score:state=>state.setting.jsry_4_score,
			  bs_password:state=>state.setting.bs_password,
			}),
			...mapGetters([
			  'getData',
			]),
		},
		data() {
			return {
				tabIndex:0,
			}
		},
		mounted(){
		  this.getList()
		},
		methods:{
			...mapMutations(['editState','editParamsState']),
			vModel(key,val){
			  this.editState({key,val})
			},
			vModelParams(name,key,val){
			  this.editParamsState({name,key,val})
			},
			handleClick(tab, event) {
			 // console.log(tab, event);
			},
			getList(){
			  this.axios.get('/manage/setting/index').then(res=>{
				if(res.status){
				  if(res.data.length > 0){
					  this.$store.commit('initSetting',res.data)
				  }
				}
			  })
			},
			submit(){
			  this.axios.post('/manage/setting/save',this.getData).then(res=>{
				if(res.status){
				  this.$message.success(res.msg)
				}else{
				  this.$message.error(res.msg)
				}
			  })
			},
		}
	}
</script>

<style>
</style>